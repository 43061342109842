export const PROVIDER_OPT = {
  azureADB2C: 'azure-ad-b2c',
  azureAD: 'azure-ad',
  google: 'google',
  dropbox: 'dropbox',
} as const;
export const AUTH_ACTIONS = { signin: 'signin', signout: 'signout' } as const;

export const TOKENS = {
  accessToken: 'access_token',
  refreshToken: 'refresh_token',
} as const;
