'use client';

import { getCookie } from 'cookies-next';
import { TOKENS } from 'src/const/auth';
import { isString } from 'src/types/type-guards';
import { verifyExpiry } from 'src/utils/token';

import env from '@client/src/env';

import { logout } from 'src/auth/logout';
import fetcher from './fetcher';

function isAuthError(res: any) {
  return (
    res?.errors &&
    (res?.errors[0]?.message === 'Unauthenticated' ||
      res?.errors[0]?.message === 'unauthorized')
  );
}

function extractAccessToken() {
  return getCookie(TOKENS.accessToken);
}

async function fetchAccessToken() {
  await fetch(
    env.nextPublicApiTokens,
    // process.env.NEXT_PUBLIC_API_TOKENS || 'http://localhost:3001/api/tokens',
    {
      method: 'POST',
      credentials: 'include',
    },
  );
}

async function fetchWithAuth(
  input: RequestInfo | URL,
  init?: RequestInit | undefined,
): Promise<Response> {
  let accessToken = extractAccessToken();
  const isAccessTokenValid =
    isString(accessToken) &&
    verifyExpiry(isString(accessToken) ? accessToken : null);
  if (!isAccessTokenValid) {
    try {
      await fetchAccessToken();
      accessToken = extractAccessToken();
    } catch (err) {
      console.log(err);
      throw new Error(`Unauthorized`);
    }
  }

  let response;
  try {
    response = await fetcher(accessToken as string, input, init);

    if (isAuthError(response)) {
      await fetchAccessToken();
      accessToken = extractAccessToken();

      response = await fetcher(accessToken as string, input, init);
    }
    if (isAuthError(response)) {
      // if again means that user is not authenticated
      logout(); // clean any session data
    }
    return response;
  } catch (err) {
    console.log('fetch with auth error', err);
  }
  return response;
}

export default fetchWithAuth;
