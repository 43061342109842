'use client';

import cx from 'classnames';
import { useTranslations } from 'next-intl';
import { ElementType, forwardRef, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

// Importing mainTheme and any necessary TailwindCSS utilities
// Note: You would replace the values with your theme's specific values or the closest Tailwind equivalent.

// Tailwind doesn't directly support dynamic prop-based styling in the same way,
// so you would need to conditionally apply classes in your React component based on props

type ButtonProps = {
  hasIcon?: boolean;
  outline?: boolean;
  text?: string;
  children?: ReactNode;
  className: string;
  type: 'button' | 'reset' | 'submit' | undefined;
  disabled: boolean;
};

const Button = ({
  hasIcon,
  outline,
  text,
  className,
  ...props
}: ButtonProps) => {
  const sizeClass = 'w-fit';
  const paddingClass = hasIcon ? 'p-3' : 'p-4';
  const textColorClass =
    outline || text
      ? 'text-[var(--one-theme-color-primary-600)]'
      : 'text-black';
  const backgroundColorClass =
    outline || text
      ? 'bg-transparent'
      : 'bg-[var(--one-theme-color-brand-100)]';
  const borderRadiusClass = 'rounded-lg';

  return (
    <button
      className={twMerge(
        `
        flex flex-wrap gap-2 justify-center items-center
        ${sizeClass} max-h-fit min-w-[100px]
        ${paddingClass}
        text-sm font-bold text-center leading-none
        ${textColorClass} ${backgroundColorClass}
        ${borderRadiusClass} cursor-pointer
        transition-opacity duration-300 ease-in-out
        outline-none
        disabled:bg-gray-100 disabled:cursor-not-allowed
        focus:outline-none
        hover:opacity-70 hover:bg-[var(--one-theme-color-neutral-200)]
        border
        border-gray-300
        border-solid
        `,
        className,
      )}
      {...props}
    >
      {props.children}
    </button>
  );
};

const InnerButton = ({ className, ...props }: ButtonProps) => {
  return (
    <Button
      className={`
        h-full w-24
        min-w-fit
        ${className}
        `}
      hasIcon
      {...props}
    >
      {props.children}
    </Button>
  );
};

type ButtonAddonInputProps = {
  isButtonDisabled: boolean;
  isInputDisabled: boolean;
  label: string;
  id: string;
  inputType: string;
  buttonLabel: string;
  icon: ElementType;
  error?: string;
  disclaimerClassName?: string;
};

export const ButtonAddonInput = forwardRef<
  HTMLInputElement,
  ButtonAddonInputProps
>(
  (
    {
      isButtonDisabled,
      isInputDisabled,
      label,
      id,
      inputType,
      buttonLabel,
      icon: Icon,
      error,
      disclaimerClassName = 'text-gray-800',
      ...rest
    },
    ref,
  ) => {
    const t = useTranslations('components.sections/home/landing/cta-button');

    return (
      <>
        <span
          className={`text-center text-lg font-bold ${disclaimerClassName}`}
        >
          {t('Join the waitlist')}
        </span>
        <div
          className={cx(
            'flex shadow-[6px_6px_6px_rgba(0,0,0,0.1)] relative h-14 rounded-lg border border-gray-300  border-solid bg-white',
            {
              '!border-red-500': error,
            },
          )}
        >
          {Icon && (
            <Icon className="w-5 h-5 absolute left-4 top-1/2 transform -translate-y-1/2 fill-gray-400" />
          )}
          <input
            id={id}
            ref={ref}
            placeholder={label}
            type={inputType}
            className={
              'w-full p-3 pl-12 border-none rounded-tl-lg rounded-bl-lg rounded-tr-lg rounded-br-lg outline-none'
            }
            disabled={isInputDisabled}
            {...rest}
          />
          <InnerButton
            type="submit"
            disabled={isButtonDisabled}
            className="rounded !h-11 m-auto mr-2 shadow-md text-gray-700"
          >
            {buttonLabel}
          </InnerButton>
          {/* <div className="border border-gray-300 rounded-lg">

          </div> */}
        </div>
        {error ? (
          <span className="sm:hidden text-red-500 bg-white">{error}</span>
        ) : (
          <div className="sm:hidden" />
        )}
        {/* <InnerButton
          type="submit"
          disabled={isButtonDisabled}
          className="w-40 sm:hidden m-auto text-white bg-[rgba(121,74,255,.1)] bg-gradient-to-br from-[#7a49ff] to-[#a451cf] ring-1 ring-[rgba(115,76,248,.16)] pb-3 no-underline transition-all duration-200 shadow-lg"
        >
          {buttonLabel}
        </InnerButton> */}
        <span className="hidden sm:block text-red-500 bg-white">{error}</span>
      </>
    );
  },
);
