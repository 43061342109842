import { ReactNode } from 'react';
import { SubmitHandler } from 'react-hook-form';

type FormProps<TFormValues> = {
  children: ReactNode;
  // @ts-ignore
  onSubmit: SubmitHandler<TFormValues>;
};

export function Form<TFormValues>({
  children,
  onSubmit,
  ...rest
}: FormProps<TFormValues>) {
  return (
    <form
      className="grid gap-2 w-full max-w-[800px] mx-auto"
      noValidate
      // @ts-ignore
      onSubmit={onSubmit}
      {...rest}
    >
      {children}
    </form>
  );
}
