'use client';

import graphqlFetch from '@client/src/data-access/graphql-fetch.client';
import { useTranslations } from 'next-intl';
import { usePlausible } from 'next-plausible';
import { useState, useTransition } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useForm } from 'react-hook-form';
import { MdOutlineEmail } from 'react-icons/md';

import { SEND_WAITLIST_EMAIL } from 'src/queries/sendWaitlistEmail';
import { ButtonAddonInput } from './cta-button';
import { Form } from './form';

type CTAProps = {
  disclaimerClassName?: string;
};

export function CTA(props: CTAProps) {
  const plausible = usePlausible();
  const t = useTranslations('components.sections/home/landing/cta');
  const { executeRecaptcha } = useGoogleReCaptcha();
  // const router = useRouter();
  const [success, setSuccess] = useState(false);
  const [mutationError, setMutationError] = useState(false);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ mode: 'onTouched' });

  const [isPending, startTransition] = useTransition();

  const sendForm = (formData: any) => {
    // throw new Error('sentry test - ops!');
    startTransition(async () => {
      try {
        if (!executeRecaptcha) {
          console.error('ReCaptcha not loaded');
          return;
        }
        plausible('email conversion', {
          props: {
            source: 'cta form',
          },
        });
        const recaptchaToken = await executeRecaptcha('waitlistRequest');

        const response = await graphqlFetch({
          query: SEND_WAITLIST_EMAIL,
          variables: {
            email: formData.email,
            recaptchaToken,
          },
        });
        if (response.data.sendWaitlistEmail) {
          setSuccess(response.data.sendWaitlistEmail);
        } else {
          setMutationError(true);
        }
        // await gqlFetch({
        //   query: SEND_EMAIL,
        //   variables: {
        //     email: formData.email,
        //     isDemo: true,
        //     timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        //   },
        // });
        // router.push(`${SIGNUP_CHOICE}?email=${formData.email}`);
      } catch (error) {
        console.error('Error in sendForm', error);
        setMutationError(true);
      }
    });
  };

  return (
    <Form onSubmit={handleSubmit(sendForm)}>
      <ButtonAddonInput
        disclaimerClassName={props.disclaimerClassName}
        icon={MdOutlineEmail}
        id="email"
        label={t('Your email address')}
        inputType="email"
        buttonLabel={isPending ? t('Loading') : t('join the waitlist')}
        isInputDisabled={isPending || success}
        isButtonDisabled={
          isPending ||
          success ||
          (!!errors?.email &&
            !errors.email.message?.toString().includes('required'))
        }
        error={
          (errors.email?.message ||
            (mutationError
              ? t('Something went wrong, please try again!')
              : '')) as string
        }
        {...register('email', {
          required: t('Email address is required'),
          pattern: {
            value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i,
            message: t('You have to provide valid email address'),
          },
        })}
      />
      {success ? (
        <p className="text-brand-300 font-semibold">{t('Email added')}</p>
      ) : null}
    </Form>
  );
}
